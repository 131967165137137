import { first } from 'lodash';
import React from 'react';
import IWCDubaiShoppingCartPage from '../components/ShoppingCart/IWCDubaiShoppingCartPage';
import ShoppingCartPage from '../components/ShoppingCart/ShoppingCartPage';
import {
  alsBrandId,
  chopardBrandId,
  chopardHappySportVirtualExpId,
  chopardHighJewelleryId,
  defaultOpeningHours,
  inspifySiteId,
  inspifyTestingSiteId,
  isIWCSGIon,
  isLoubiAirways,
  iwcBrandId,
  iwcBrazilSiteId,
  iwcChZurichBoutiqueId,
  iwcChZurichVirtualBoutiqueId,
  iwcNlAmsterdamSiteId,
  iwcSgIonBoutiqueId,
  iwcSgIonBoutiqueIdV2,
  iwcSihh2020BoutiqueId,
  iwcThaiBangkokBoutiqueId,
  iwcUaeDubaiBoutiqueId,
  iwcWW2021Id,
  jlcAuMelbourneBoutiqueId,
  jlcBrandId,
  loubiAirwaysBoutiqueId,
  loubiAirwaysCnBoutiqueId,
  loubiAirwaysJpBoutiqueId,
  loubiFutureSiteId,
  louboutinBrandId,
  piagetAuBoutiqueId,
  piagetBrandId,
  piagetGenevaBoutiqueId,
  piagetMyBoutiqueId,
  piagetSgBoutiqueId,
  piagetVnBoutiqueId,
  rduBrandId,
  rogerDubuisRetailId,
  rogerDubuisSgBoutiqueId,
  rogerDubuisWholeSaleId,
  rogerDubuisWW2021Id,
  vanCleefArpelsBrandId,
  vanCleefArpelsUsBeverlyHillsSiteId,
  isRDULite,
  vacBrandId,
  inspifyBrandId,
  iwcSgIonSalesLoftSiteId,
  inspifyTestBrandSiteId,
  inspifyTestBrandId,
  vanCleefArpelsUsSiliconValleyFairSiteId,
  inspifyGCCSiteId,
  vanCleefArpelsUsKingOfPrussiaSiteId,
  inspifyJPSilkRoadSiteId,
  chopardHappySportKorea,
  chopardHappySportGenevaHQ,
  chopardHappySportGenevaHQTraining,
  assetBaseUrl,
  inspifyInverseSiteId,
  mercedesBenzSiteId,
  mercedesBenzStoreId
} from '../config';
import {
  AppointmentType,
  IDevicePermission,
  ILanguageOption,
  IMeeting,
  INudge,
  IOpeningHoursByWeekday,
  IProduct,
  IStore,
  IVirtualBoutiqueAppointmentContact,
  IVirtualBoutiqueConfig,
  IVirtualBoutiqueContact,
  IVirtualBoutiqueShopContact,
  ProductGrouping,
  VirtualBoutiqueMode
} from '../interfaces';
import { isInChina } from '../utils/stores';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import TermsOfUse from './Legal/TermsOfUse';
import LoubiAirwaysLounge from './Meeting/Lounge/LoubiAirwaysLounge';
import LoubiFutureLounge from './Meeting/Lounge/LoubiFutureLounge';
import Lounge from './Meeting/Lounge/Lounge';
import GradientPickedProduct from './PickOfTheMonth/GradientBgPickedProduct';
import { PotmContent } from './PickOfTheMonth/mockData';
import PickedProduct from './PickOfTheMonth/PickedProduct';
import StripedPickedProduct from './PickOfTheMonth/StripedPickedProduct';
import TwoColPickedProduct from './PickOfTheMonth/TwoColPickedProduct';
import ALSProductCallToActionButton from './Product/ActionButton/ALSProductCallToActionButton';
import ChatButton from './Product/ActionButton/ChatButton';
import EnquireButton, {
  EnquireNowContact
} from './Product/ActionButton/EnquireButton';
import GenericProductCallToActionButton from './Product/ActionButton/GenericProductCallToActionButton';
import IWCChZurichProductCallToActionButton from './Product/ActionButton/IWCChZurichProductCallToActionButton';
import IWCDubaiProductCallToActionButton from './Product/ActionButton/IWCDubaiProductCallToActionButton';
import IWCNLAmsterdamProductCallToActionButton from './Product/ActionButton/IWCNLAmsterdamProductCallToActionButton';
import IWCSGProductCallToActionButton from './Product/ActionButton/IWCSGProductCallToActionButton';
import IWCThailandProductCallToActionButton from './Product/ActionButton/IWCThailandProductCallToActionButton';
import JLCProductCallToActionButton from './Product/ActionButton/JLCProductCallToActionButton';
import PiagetProductCallToActionButton from './Product/ActionButton/PiagetProductCallToActionButton';
import ZaloChatButton from './Product/ActionButton/ZaloChatButton';
import IWCWarrantyBadge from './Product/ProductDetails/IWCWarrantyBadge';
import JLCWarrantyBadge from './Product/ProductDetails/JLCWarrantyBadge';
import ProductDetails from './Product/ProductDetails/ProductDetails';
import CartForm from './ShoppingCart/CartForm';
import ChopardShoppingForm from './ShoppingCart/ChopardShoppingForm';
import IWCDubaiShoppingForm from './ShoppingCart/IWCDubaiShoppingForm';
import JLCShoppingForm from './ShoppingCart/JLCShoppingForm';
import PiagetShoppingForm from './ShoppingCart/PiagetShoppingForm';
import RDSGShoppingForm from './ShoppingCart/RDSGShoppingForm';
import Showcase from './Showcase';
import Appointment from './VirtualBoutique/Appointment/Appointment';
import AppointmentButton from './VirtualBoutique/Appointment/AppointmentButton';
import AppointmentButtonLink from './VirtualBoutique/Appointment/AppointmentButtonLink';
import IWCAmsterdamAppointment from './VirtualBoutique/Appointment/IWCAmsterdamAppointment';
import IWCDubaiAppointment from './VirtualBoutique/Appointment/IWCDubaiAppointment';
import GenericIWCAppointmentForm from './VirtualBoutique/Appointment/GenericIWCAppointmentForm';
import JLCAppointment from './VirtualBoutique/Appointment/JLCAppointment';
import LoubiAirwaysAppointment from './VirtualBoutique/Appointment/LoubiAirwaysAppointment';
import PiagetAppointment from './VirtualBoutique/Appointment/PiagetAppointmentForm';
import RDUAppointment from './VirtualBoutique/Appointment/RDUAppointmentForm';
import VideoAppointment from './VirtualBoutique/Appointment/VideoAppointment';
import ConditionsOfSaleButton from './VirtualBoutique/ConditionsOfSaleButton';
import ContactButtonWithCallNow from './VirtualBoutique/Contact/ContactButtonWithCallNow';
import ContactButtonWithWhatsapp from './VirtualBoutique/Contact/ContactButtonWithWhatsapp';
import ContactButtonWithZalo from './VirtualBoutique/Contact/ContactButtonWithZalo';
import ContactPopupWithWhatsapp from './VirtualBoutique/Contact/ContactPopupWithWhatsapp';
import ChopardHappySportCustomComponent from './VirtualBoutique/CustomComponent/ChopardHappySportCustomComponent';
import GenericClosingVideo from './VirtualBoutique/CustomComponent/GenericClosingVideo';
import GenericWelcomeVideo from './VirtualBoutique/CustomComponent/GenericWelcomeVideo';
import IWCDubaiCustomComponent from './VirtualBoutique/CustomComponent/IWCDubaiCustomComponent';
import JLCMelbourneCustomComponent from './VirtualBoutique/CustomComponent/JLCMelbourneCustomComponent';
import LoubiFutureCustomComponent from './VirtualBoutique/CustomComponent/LoubiFutureCustomComponent';
import LFExitVideo from './VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LFExitVideo';
import LoubiAirwaysCustomComponent from './VirtualBoutique/CustomComponent/LouboutinCustomComponent';
import LoubiAirwaysJpCustomComponent from './VirtualBoutique/CustomComponent/LouboutinJpCustomComponent';
import RDUSGCustomComponent from './VirtualBoutique/CustomComponent/RDUSGCustomComponent';
import RDUWW21CustomComponent from './VirtualBoutique/CustomComponent/RDUWW21CustomComponent';
import VCACustomComponent from './VirtualBoutique/CustomComponent/VCACustomComponent';
import Landing from './VirtualBoutique/Landing';
import EmptyLanding from './VirtualBoutique/Landing/EmptyLanding';
import IWCDubaiLanding from './VirtualBoutique/Landing/IWCDubaiLanding';
import IWCSihh2020Landing from './VirtualBoutique/Landing/IWCSihh2020Landing';
import LoubiAirwaysJapanLanding from './VirtualBoutique/Landing/LoubiAirwaysJapanLanding';
import VirtualBoutiqueLite from './VirtualBoutique/Lite';
import PrivateVirtualBoutiqueLite from './VirtualBoutique/Lite/PrivateVirtualBoutiqueLite';
import KRPano from './VirtualBoutique/Pano/KRPano';
import StoreLocation from './VirtualBoutique/PiagetBoutiqueLocation/StoreLocation';
import StoreLocationButton from './VirtualBoutique/PiagetBoutiqueLocation/StoreLocationButton';
import ShareButton from './VirtualBoutique/ShareButton';
import VB360Veil from './VirtualBoutique/VB360Veil';
import RDUSGWelcome from './VirtualBoutique/WelcomeToScene/RDUSGWelcome';
import AdvisorAccessControls from './Meeting/MeetingControls/AdvisorAccessControls';
import InspifyAdvisorMeetingControls from './VirtualBoutique/CustomComponent/InspifyCustomComponent/InspifyAdvisorMeetingControls';
import InspifyCustomComponent from './VirtualBoutique/CustomComponent/InspifyCustomComponent';
import VACAppointment from './VirtualBoutique/Appointment/VACAppointment';
import PoweredByInspify from './Legal/PoweredByInspify';
import InspifyFeedback, {
  FeedbackType
} from './Meeting/Feedback/InspifyFeedback';
import VCASiliconValleyCustomComponent from './VirtualBoutique/CustomComponent/VCASiliconValleyCustomComponent';
import VCALanding from './VirtualBoutique/Landing/VCALanding';
import SinglePickedProduct from './PickOfTheMonth/SinglePickedProduct';
import VCAKingOfPrussiaProductActionButton from './Product/ActionButton/VCAKingOfPrussiaProductActionButton';
import ContactPopupWithCallNow from './VirtualBoutique/Contact/ContactPopupWithCallNow';
import ChopardProductDetails from './Product/ProductDetails/ChopardProductDetails';
import { ChopardHSKoreanCustomComponent } from './VirtualBoutique/CustomComponent/ChopardHSKoreanCustomComponent';
import { isOnboardingBrand } from '../utils/window';
import ChopardAdvisorMeetingControls from './VirtualBoutique/CustomComponent/ChopardHappySportCustomComponent/ChopardAdvisorMeetingControls';
import { SBMicrositeAdditionalComponent } from '../advisorHub/components/StoryBook/Mirosite/SBMicrosite';
import InverseLanding from './PixelStream/PSCustomLanding/InverseLanding';
import GenericLanding from './PixelStream/PSCustomLanding/GenericLanding';
import InverseDisconnect from './PixelStream/PSCustomLanding/InverseDisconnected';
import GenericDisconnected from './PixelStream/PSCustomLanding/GenericDisconnected';
import MercedesLanding from './PixelStream/PSCustomLanding/MercedesLanding';
import MercedesCustomComponent from './PixelStream/MercedesBenz/MercedesCustomComponent';
import MercedesAdvisorMeetingControl from './VirtualBoutique/CustomComponent/MercedesBenz/MercedesAdvisorMeetingControl';
import MercedesBenzCustomComponent from './VirtualBoutique/CustomComponent/MercedesBenzCustomComponent';

export const getProductDetails = (
  product: IProduct,
  colors: string[],
  handleColorClick: (index: number) => void,
  isCustomizable?: boolean,
  storeId?: string,
  hideVariations?: boolean,
  hideReferences?: boolean
) => {
  return storeId === chopardHappySportKorea ||
    storeId === chopardHappySportGenevaHQ ? (
    <ChopardProductDetails
      product={product}
      colors={colors}
      handleColorClick={handleColorClick}
      key={product && product.id}
    />
  ) : (
    <ProductDetails
      product={product}
      colors={colors}
      handleColorClick={handleColorClick}
      key={product && product.id}
      isCustomizable={isCustomizable}
      hideVariations={hideVariations}
      hideReferences={hideReferences}
    />
  );
};

export const getPickedProduct = (product: IProduct, content: PotmContent) => {
  if (['feb2021', 'mar2021', 'april2021'].includes(content.month))
    return (
      <StripedPickedProduct
        product={product}
        content={content}
        key={product && product.id}
      />
    );
  if (content.month === 'jan2021')
    return (
      <TwoColPickedProduct
        product={product}
        content={content}
        key={product && product.id}
      />
    );
  if (content.month === 'jul2021')
    return (
      <GradientPickedProduct
        product={product}
        content={content}
        key={product && product.id}
      />
    );
  if (content.month === 'april2022')
    return (
      <SinglePickedProduct
        product={product}
        content={content}
        key={product && product.id}
      />
    );
  return (
    <PickedProduct
      product={product}
      content={content}
      key={product && product.id}
    />
  );
};

export const getShowcase = (
  products: IProduct[],
  description: string,
  title: string,
  configurationId: string,
  productGrouping: ProductGrouping[] | undefined | null
) => {
  const brandId = first(products)?.brandId;
  const desc = brandId === vanCleefArpelsBrandId ? title : description;
  return (
    <Showcase
      products={products}
      description={desc}
      configurationId={configurationId}
      productGrouping={productGrouping}
      showLogo={true}
    />
  );
};

const getIWCAppointmentComponent = (
  brandId: string,
  appointmentContacts: IVirtualBoutiqueAppointmentContact[],
  openingHours: string[],
  logoUrl: string,
  storeId: string,
  open: boolean,
  timezoneGMTOffset: number,
  timezoneName: string,
  phoneCountryCode: string,
  onClose: () => void,
  openingHoursByWeekday: IOpeningHoursByWeekday,
  appointmentSlotDurationInMinutes: number,
  visitHours?: string[]
) => {
  switch (storeId) {
    case iwcThaiBangkokBoutiqueId:
    case iwcSgIonBoutiqueId:
    case iwcSgIonBoutiqueIdV2:
    case iwcBrazilSiteId:
      return (
        <GenericIWCAppointmentForm
          appointmentContacts={appointmentContacts}
          logoUrl={logoUrl}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
          openingHoursByWeekday={openingHoursByWeekday}
          appointmentSlotDurationInMinutes={appointmentSlotDurationInMinutes}
        />
      );
    case iwcNlAmsterdamSiteId:
      return (
        <IWCAmsterdamAppointment
          appointmentContacts={appointmentContacts}
          logoUrl={logoUrl}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          openingHoursByWeekday={openingHoursByWeekday}
          appointmentSlotDurationInMinutes={appointmentSlotDurationInMinutes}
        />
      );
    case iwcUaeDubaiBoutiqueId:
      return (
        <IWCDubaiAppointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          brandId={brandId}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );

    default:
      return (
        <Appointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          brandId={brandId}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
  }
};

export const getVideoAppointmentComponent = (
  config: IVirtualBoutiqueConfig,
  storeId: string,
  advisorId: string,
  nudge: INudge,
  open: boolean,
  onClose: () => void,
  visitHours?: string[]
) => {
  const {
    brandId,
    appointmentContacts,
    openingHours: openHours,
    openingHoursByWeekday,
    logoUrl,
    timezoneGMTOffset,
    timezoneName,
    phoneCountryCode
  } = config;

  const openingHours = openHours?.length ? openHours : defaultOpeningHours;

  return (
    <VideoAppointment
      appointmentContacts={appointmentContacts}
      openingHours={openingHours}
      openingHoursByWeekday={openingHoursByWeekday}
      logoUrl={logoUrl}
      brandId={brandId}
      storeId={storeId}
      open={open}
      timezoneGMTOffset={timezoneGMTOffset}
      timezoneName={timezoneName}
      phoneCountryCode={phoneCountryCode}
      onClose={onClose}
      visitHours={visitHours}
      advisorId={advisorId}
      nudge={nudge}
    />
  );
};

export const getAppointmentComponent = (
  config: IVirtualBoutiqueConfig,
  storeId: string,
  open: boolean,
  onClose: () => void,
  visitHours?: string[]
) => {
  const {
    brandId,
    appointmentContacts,
    openingHours: openHours,
    openingHoursByWeekday,
    appointmentSlotDurationInMinutes,
    logoUrl,
    timezoneGMTOffset,
    timezoneName,
    phoneCountryCode
  } = config;

  const openingHours = openHours?.length ? openHours : defaultOpeningHours;
  switch (brandId) {
    case iwcBrandId:
      return getIWCAppointmentComponent(
        brandId,
        appointmentContacts,
        openingHours,
        logoUrl,
        storeId,
        open,
        timezoneGMTOffset,
        timezoneName,
        phoneCountryCode,
        onClose,
        openingHoursByWeekday,
        appointmentSlotDurationInMinutes,
        visitHours
      );
    case piagetBrandId:
      return (
        <PiagetAppointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          openingHoursByWeekday={openingHoursByWeekday}
          appointmentSlotDurationInMinutes={appointmentSlotDurationInMinutes}
          logoUrl={logoUrl}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
    case rduBrandId:
      return (
        <RDUAppointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
    case jlcBrandId:
      return (
        <JLCAppointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          brandId={brandId}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
    case louboutinBrandId:
      return (
        <LoubiAirwaysAppointment
          openingHours={openingHours}
          brandId={config.brandId}
          storeId={loubiAirwaysJpBoutiqueId}
          open={open}
          timezoneGMTOffset={config.timezoneGMTOffset}
          timezoneName={config.timezoneName}
          phoneCountryCode={config.phoneCountryCode}
          onClose={onClose}
        />
      );
    case vacBrandId:
      return (
        <VACAppointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          brandId={brandId}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
    default:
      return (
        <Appointment
          appointmentContacts={appointmentContacts}
          openingHours={openingHours}
          logoUrl={logoUrl}
          brandId={brandId}
          storeId={storeId}
          open={open}
          timezoneGMTOffset={timezoneGMTOffset}
          timezoneName={timezoneName}
          phoneCountryCode={phoneCountryCode}
          onClose={onClose}
          visitHours={visitHours}
        />
      );
  }
};

export const getLandingComponent = (
  storeId: string,
  brandName: string,
  logoUrl: string,
  darkLogoUrl: string,
  backgroundImageUrl: string,
  mobileBackgroundImageUrl: string,
  languages: ILanguageOption[],
  showAppointmentForm: () => void,
  onDismiss: () => void
) => {
  if (isRDULite(storeId)) {
    return <EmptyLanding onDismiss={onDismiss} />;
  }
  switch (storeId) {
    case iwcUaeDubaiBoutiqueId:
      return (
        <IWCDubaiLanding
          storeId={storeId}
          brandName={brandName}
          logoUrl={logoUrl}
          backgroundImageUrl={backgroundImageUrl}
          mobileBackgroundImageUrl={mobileBackgroundImageUrl}
          languages={languages}
          onDismiss={onDismiss}
        />
      );
    case iwcSihh2020BoutiqueId:
    case iwcChZurichVirtualBoutiqueId:
      return (
        <IWCSihh2020Landing
          storeId={storeId}
          brandName={brandName}
          logoUrl={logoUrl}
          backgroundImageUrl={backgroundImageUrl}
          mobileBackgroundImageUrl={mobileBackgroundImageUrl}
          languages={languages}
          onDismiss={onDismiss}
        />
      );
    case jlcAuMelbourneBoutiqueId:
      return (
        <Landing
          storeId={storeId}
          brandName={brandName}
          backgroundImageUrl={backgroundImageUrl}
          mobileBackgroundImageUrl={mobileBackgroundImageUrl}
          languages={languages}
          showAppointmentForm={showAppointmentForm}
          onDismiss={onDismiss}
        />
      );
    case piagetSgBoutiqueId:
    case piagetMyBoutiqueId:
    case piagetAuBoutiqueId:
    case piagetVnBoutiqueId:
    case piagetGenevaBoutiqueId:
    case loubiAirwaysBoutiqueId:
    case loubiAirwaysCnBoutiqueId:
    case rogerDubuisSgBoutiqueId:
    case rogerDubuisWW2021Id:
    case rogerDubuisWholeSaleId:
    case rogerDubuisRetailId:
      return <EmptyLanding onDismiss={onDismiss} />;
    case loubiAirwaysJpBoutiqueId:
      return (
        <LoubiAirwaysJapanLanding
          logoUrl={darkLogoUrl}
          showAppointmentForm={showAppointmentForm}
          onDismiss={onDismiss}
        />
      );
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
      return (
        <VCALanding
          storeId={storeId}
          brandName={brandName}
          logoUrl={logoUrl}
          backgroundImageUrl={backgroundImageUrl}
          mobileBackgroundImageUrl={mobileBackgroundImageUrl}
          languages={languages}
          showAppointmentForm={showAppointmentForm}
          externalAppointment="https://bookings-us.qudini.com/Q8636PK35HK/venues"
          onDismiss={onDismiss}
        />
      );
    default:
      return (
        <Landing
          storeId={storeId}
          brandName={brandName}
          logoUrl={logoUrl}
          backgroundImageUrl={backgroundImageUrl}
          mobileBackgroundImageUrl={mobileBackgroundImageUrl}
          languages={languages}
          showAppointmentForm={showAppointmentForm}
          onDismiss={onDismiss}
        />
      );
  }
};

export const getProductCTAButton = (
  product: IProduct,
  storeId: string | undefined,
  storeConfig?: IVirtualBoutiqueConfig
) => {
  //customize for loubi's storybook
  if (storeConfig?.fromStorybook && storeConfig?.contacts) {
    return <GenericProductCallToActionButton product={product} />;
  }
  switch (product.brandId?.toLowerCase()) {
    case iwcBrandId:
      return getIWCProductCTAButton(product, storeId);
    case alsBrandId:
      return <ALSProductCallToActionButton product={product} />;
    case jlcBrandId:
      return <JLCProductCallToActionButton product={product} />;
    case piagetBrandId:
      return <PiagetProductCallToActionButton product={product} />;
    case vanCleefArpelsBrandId:
      return (
        <VCAKingOfPrussiaProductActionButton
          product={product}
          storeId={storeId}
        />
      );
    case rduBrandId:
    case inspifyBrandId:
      return <GenericProductCallToActionButton product={product} />;
    default:
      return null;
  }
};

export const getAppointmentButton = (storeId: string) => {
  switch (storeId) {
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
      return (
        <AppointmentButtonLink url="https://bookings-us.qudini.com/Q8636PK35HK/venues" />
      );
    case iwcBrazilSiteId:
      return (
        <AppointmentButton
          appointmentTypes={[AppointmentType.VIRTUAL_WALKTHROUGH]}
        />
      );
    case iwcThaiBangkokBoutiqueId:
      return (
        <AppointmentButton appointmentTypes={[AppointmentType.PHYSICAL]} />
      );
    default:
      return <AppointmentButton />;
  }
};

const getIWCProductCTAButton = (
  product: IProduct,
  storeId: string | undefined
) => {
  switch (storeId) {
    case iwcThaiBangkokBoutiqueId:
      return <IWCThailandProductCallToActionButton product={product} />;
    case iwcSgIonBoutiqueId:
    case iwcSgIonBoutiqueIdV2:
      return <IWCSGProductCallToActionButton product={product} />;
    case iwcNlAmsterdamSiteId:
      return <IWCNLAmsterdamProductCallToActionButton product={product} />;
    case iwcUaeDubaiBoutiqueId:
      return <IWCDubaiProductCallToActionButton product={product} />;
    case iwcChZurichBoutiqueId:
    case iwcChZurichVirtualBoutiqueId:
      return <IWCChZurichProductCallToActionButton product={product} />;
    case iwcBrazilSiteId:
      return (
        <GenericProductCallToActionButton
          product={product}
          appointmentTypes={[AppointmentType.VIRTUAL_WALKTHROUGH]}
        />
      );
    default:
      return <GenericProductCallToActionButton product={product} />;
  }
};

export const getLoungeComponent = (
  config: IVirtualBoutiqueConfig,
  storeId: string,
  joinRoom: () => void,
  permissionStatus?: IDevicePermission,
  isBrowserSupported?: boolean,
  onToggleMuteVideo?: (mute: boolean) => void
) => {
  const loungeProps = {
    config,
    storeId,
    joinRoom,
    permissionStatus,
    isBrowserSupported,
    onToggleMuteVideo
  };
  if (isLoubiAirways(storeId)) {
    return <LoubiAirwaysLounge {...loungeProps} />;
  }

  if (storeId === loubiFutureSiteId) {
    return <LoubiFutureLounge {...loungeProps} />;
  }
  return <Lounge {...loungeProps} />;
};

export const getPano = (
  config: IVirtualBoutiqueConfig,
  store: IStore,
  className: string
) => {
  return <KRPano config={config} store={store} classname={className} />;
};

export const getPresentationElements = (config: IVirtualBoutiqueConfig) => {
  switch (config.brandId) {
    case piagetBrandId:
      return <img src={config.lightLogoUrl} alt="logo" className="logo" />;
    default:
      return null;
  }
};

export const getAppointmentMessageHeader = () => {
  return 'messages_and_watch_enquiries';
};

export const getBadge = (storeId: string) => {
  if (
    storeId === iwcUaeDubaiBoutiqueId ||
    storeId === iwcSgIonBoutiqueId ||
    storeId === iwcThaiBangkokBoutiqueId
  ) {
    return <IWCWarrantyBadge />;
  }
  if (storeId === jlcAuMelbourneBoutiqueId) {
    return <JLCWarrantyBadge />;
  }
  return null;
};

export const getChatPanel = (
  config: IVirtualBoutiqueConfig,
  storeId: string
) => {
  if (storeId === iwcUaeDubaiBoutiqueId) {
    return (
      <ContactPopupWithWhatsapp
        config={config}
        additionalContactsInChatFilter={(c: IVirtualBoutiqueContact) =>
          c.nameKey === 'iwc_other_boutiques'
        }
      />
    );
  }

  if (storeId === iwcThaiBangkokBoutiqueId) {
    return (
      <ContactPopupWithCallNow config={config} showCallNowForMobile={true} />
    );
  }
  return <ContactPopupWithWhatsapp config={config} />;
};

export const getChatButton = (
  config: IVirtualBoutiqueConfig,
  storeId: string
) => {
  if (storeId === iwcUaeDubaiBoutiqueId) {
    return (
      <ContactButtonWithWhatsapp
        config={config}
        additionalContactsInChatFilter={(c: IVirtualBoutiqueContact) =>
          c.nameKey === 'iwc_other_boutiques'
        }
        labelTextKey="contact_us_now"
      />
    );
  }
  if (storeId === piagetVnBoutiqueId) {
    return <ContactButtonWithZalo config={config} />;
  }
  if (storeId === iwcNlAmsterdamSiteId) {
    return (
      <ContactButtonWithCallNow
        config={config}
        showCallNowForMobile={storeId === iwcNlAmsterdamSiteId}
      />
    );
  }

  if (storeId)
    if (storeId === iwcThaiBangkokBoutiqueId) {
      return (
        <ContactButtonWithCallNow config={config} showCallNowForMobile={true} />
      );
    }
  return <ContactButtonWithWhatsapp config={config} />;
};

export const getSlideNavShareButton = (storeId: string, message: string) => {
  if (isInChina(storeId)) {
    return null;
  }
  if (isLoubiAirways(storeId)) {
    return null;
  }
  return <ShareButton message={message} />;
};

export const getConditionOfSaleButton = (storeId: string, setShow: any) => {
  if (storeId === iwcUaeDubaiBoutiqueId) {
    return <ConditionsOfSaleButton setShow={setShow} />;
  }

  return null;
};

export const getShopNowForm = (
  shopContacts: IVirtualBoutiqueShopContact[],
  storeId: string,
  phoneCountryCode: string,
  deliveryFee?: number
) => {
  const storeIDsForShoppingCart = [
    iwcThaiBangkokBoutiqueId,
    iwcNlAmsterdamSiteId,
    inspifyTestingSiteId,
    inspifySiteId,
    inspifyGCCSiteId,
    inspifyJPSilkRoadSiteId,
    iwcSgIonBoutiqueId,
    iwcSgIonBoutiqueIdV2,
    iwcSgIonSalesLoftSiteId
  ];
  if (storeIDsForShoppingCart.includes(storeId)) {
    return (
      <CartForm
        shopContacts={shopContacts}
        storeId={storeId}
        brandId={iwcBrandId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }

  if (storeId === iwcUaeDubaiBoutiqueId) {
    return (
      <IWCDubaiShoppingForm
        shopContacts={shopContacts}
        storeId={storeId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }

  if (storeId === jlcAuMelbourneBoutiqueId) {
    return (
      <JLCShoppingForm
        shopContacts={shopContacts}
        storeId={storeId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }
  if (storeId === rogerDubuisSgBoutiqueId) {
    return (
      <RDSGShoppingForm
        shopContacts={shopContacts}
        storeId={storeId}
        brandId={rduBrandId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }

  if (
    storeId === piagetAuBoutiqueId ||
    storeId === piagetMyBoutiqueId ||
    storeId === piagetVnBoutiqueId
  ) {
    return (
      <PiagetShoppingForm
        shopContacts={shopContacts}
        storeId={storeId}
        brandId={piagetBrandId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }

  if (storeId === chopardHappySportVirtualExpId) {
    return (
      <ChopardShoppingForm
        shopContacts={shopContacts}
        storeId={storeId}
        brandId={chopardBrandId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
      />
    );
  }
};

export const getShoppingCartPage = (
  shopContacts: IVirtualBoutiqueShopContact[],
  storeId: string,
  phoneCountryCode: string,
  deliveryFee?: number,
  onClose?: () => void
) => {
  if (storeId === iwcUaeDubaiBoutiqueId) {
    return (
      <IWCDubaiShoppingCartPage
        shopContacts={shopContacts}
        storeId={storeId}
        phoneCountryCode={phoneCountryCode}
        deliveryFee={deliveryFee}
        onClose={onClose}
      />
    );
  }

  return (
    <ShoppingCartPage
      shopContacts={shopContacts}
      storeId={storeId}
      phoneCountryCode={phoneCountryCode}
      deliveryFee={deliveryFee}
    />
  );
};

export const getVBCustomComponent = (
  storeId: string,
  sendPSCommand?: (str: string) => void
) => {
  if (storeId === rogerDubuisSgBoutiqueId) {
    return <RDUSGCustomComponent />;
  }
  if (storeId === iwcUaeDubaiBoutiqueId) {
    return <IWCDubaiCustomComponent />;
  }
  if (storeId === jlcAuMelbourneBoutiqueId) {
    return <JLCMelbourneCustomComponent />;
  }
  if (storeId === loubiAirwaysJpBoutiqueId) {
    return <LoubiAirwaysJpCustomComponent />;
  }
  if (storeId === loubiFutureSiteId) {
    return <LoubiFutureCustomComponent />;
  }
  if (isLoubiAirways(storeId)) {
    return <LoubiAirwaysCustomComponent />;
  }
  if (
    [rogerDubuisWW2021Id, rogerDubuisWholeSaleId, rogerDubuisRetailId].includes(
      storeId
    )
  ) {
    return <RDUWW21CustomComponent />;
  }
  if (storeId === chopardHappySportVirtualExpId) {
    return <ChopardHappySportCustomComponent />;
  }
  if (
    storeId === chopardHappySportKorea ||
    storeId === chopardHappySportGenevaHQ
  ) {
    return <ChopardHSKoreanCustomComponent sendPSCommand={sendPSCommand} />;
  }
  if (
    storeId === vanCleefArpelsUsSiliconValleyFairSiteId ||
    storeId === vanCleefArpelsUsKingOfPrussiaSiteId
  ) {
    return <VCASiliconValleyCustomComponent storeId={storeId} />;
  }
  if (storeId === vanCleefArpelsUsBeverlyHillsSiteId) {
    return <VCACustomComponent />;
  }
  if (
    storeId === inspifyTestingSiteId ||
    storeId === inspifySiteId ||
    storeId === inspifyGCCSiteId ||
    storeId === inspifyJPSilkRoadSiteId ||
    storeId === chopardHappySportGenevaHQTraining ||
    storeId === mercedesBenzStoreId
  ) {
    return <InspifyCustomComponent siteId={storeId} />;
  }
  if (storeId === mercedesBenzSiteId) {
    return <MercedesBenzCustomComponent />;
  }
  return null;
};

export const getVBWelcomeToSceneComponent = (storeId: string) => {
  if (storeId === rogerDubuisSgBoutiqueId) {
    return <RDUSGWelcome />;
  }
  return null;
};

export const getVBCustomVeil = (storeId: string) => {
  switch (storeId) {
    case iwcNlAmsterdamSiteId:
    case vanCleefArpelsUsBeverlyHillsSiteId:
    case vanCleefArpelsUsSiliconValleyFairSiteId:
    case vanCleefArpelsUsKingOfPrussiaSiteId:
      return <VB360Veil />;
    default:
      return null;
  }
};

export const getShopNowDisclaimer = (storeId: string, t: any) => {
  if (isIWCSGIon(storeId)) {
    return (
      <p>
        {t('vb:iwc_tsc_shopping_cart')}{' '}
        <a target="_blank" href="https://stores.iwc.com/">
          {t('vb:here')}
        </a>
      </p>
    );
  }
  if (storeId === iwcThaiBangkokBoutiqueId) {
    return (
      <p>
        {t('vb:iwc_thailand_tsc_shopping_cart')}{' '}
        <a target="_blank" href="https://stores.iwc.com/">
          {t('vb:here')}
        </a>
      </p>
    );
  }

  if (storeId === iwcUaeDubaiBoutiqueId) {
    return (
      <p>
        {t('vb:iwc_dubai_tsc_shopping_cart')}{' '}
        <a target="_blank" href="https://stores.iwc.com/">
          {t('vb:here')}
        </a>
      </p>
    );
  }

  if (storeId === jlcAuMelbourneBoutiqueId) {
    return (
      <p>
        {t('vb:jlc_tsc_shopping_cart')}{' '}
        <a
          target="_blank"
          href="https://www.jaeger-lecoultre.com/sg/en/boutiques.html"
        >
          {t('vb:here')}
        </a>
      </p>
    );
  }
};

export const getStoreTermsAndConditions = (storeId: string, t: any) => {
  if (storeId === rogerDubuisSgBoutiqueId) {
    return (
      <>
        {t('vb:tc_rd_sg.part_one')}{' '}
        <PrivacyPolicy privacyUrl="https://www.rogerdubuis.com/en/privacy" />.
      </>
    );
  }
  if (isIWCSGIon(storeId)) {
    return (
      <>
        {t('vb:tc_iwc_v2.part_one')}{' '}
        <TermsOfUse termsOfUseUrl="https://www.iwc.com/en/terms-and-legal/terms-of-use.html" />{' '}
        {t('vb:tc_iwc_v2.part_two')}{' '}
        <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />{' '}
        {t('vb:tc_iwc_v2.part_three')}
        <span className="sub-tc">- {t('vb:tc_iwc_v2.part_four')}</span>
        <span className="sub-tc">
          - {t('vb:tc_iwc_v2.part_five')}
          <br />
          {t('vb:tc_iwc_v2.part_six')}{' '}
          <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />
        </span>
      </>
    );
  }
  if (storeId === iwcThaiBangkokBoutiqueId) {
    return (
      <>
        {t('vb:tc_iwc_thailand.part_one')}{' '}
        <TermsOfUse termsOfUseUrl="https://www.iwc.com/en/terms-and-legal/terms-of-use.html" />{' '}
        {t('vb:tc_iwc_v2.part_two')}{' '}
        <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />{' '}
        {t('vb:tc_iwc_v2.part_three')}
        <span className="sub-tc">- {t('vb:tc_iwc_v2.part_four')}</span>
        <span className="sub-tc">
          - {t('vb:tc_iwc_v2.part_five')}
          <br />
          {t('vb:tc_iwc_v2.part_six')}{' '}
          <PrivacyPolicy privacyUrl="https://www.iwc.com/en/terms-and-legal/privacy-policy.html" />
        </span>
      </>
    );
  } else {
    return (
      <>
        {t('vb:tc_part_one')} <TermsOfUse /> {t('vb:tc_part_two')}{' '}
        <PrivacyPolicy />
        {t('vb:tc_part_three')}
      </>
    );
  }
};

export const getVBLite = (
  config: IVirtualBoutiqueConfig,
  store: IStore,
  vbMode: VirtualBoutiqueMode
) => {
  if (
    config.brandId === chopardBrandId &&
    store.id !== chopardHappySportVirtualExpId
  ) {
    return (
      <PrivateVirtualBoutiqueLite
        config={config}
        store={store}
        vbMode={vbMode}
      />
    );
  }
  return <VirtualBoutiqueLite config={config} store={store} vbMode={vbMode} />;
};

export const getWelcomeMessage = (storeId: string, brandId?: string) => {
  if (storeId === iwcWW2021Id) {
    return (
      <span>
        <span style={{ display: 'block' }}>IWC SCHAFFHAUSEN</span> WATCHES AND
        WONDERS 2021
      </span>
    );
  }
  if (storeId === chopardHighJewelleryId) {
    return (
      <span>
        The Haute Joaillerie reveals the Maison’s love for the most beautiful
        diamonds and colored stones drawn from Earth’s natural bounty.
      </span>
    );
  }
  if (brandId === inspifyBrandId || brandId === inspifyTestBrandId) {
    return (
      <span>
        Impress your audience with amazing virtual experiences.{'\n\n'}
      </span>
    );
  }
};

export const getLoungeWelcomeMessage = (
  storeId: string,
  brandName: string,
  loungeTagLine: string | undefined,
  source: string,
  t: any
) => {
  if (storeId === rogerDubuisSgBoutiqueId && source === 'medialaunch') {
    return 'Welcome to Roger Dubuis Virtual Experiential Boutique Media Launch';
  }
  if (isLoubiAirways(storeId)) {
    return <span>{t('vb:loubiairways_welcome_message').toUpperCase()}</span>;
  }
  if (storeId === loubiFutureSiteId) {
    return (
      <span>
        WELCOME TO LOUBI FUTURE
        <br /> WE HOPE YOU ENJOY THIS EXPERIENCE
      </span>
    );
  }
  if (storeId === iwcWW2021Id) {
    return (
      <span>
        <span>WELCOME TO THE</span> IWC SCHAFFHAUSEN WATCHES AND WONDERS 2021
      </span>
    );
  }
  if (storeId === rogerDubuisWW2021Id) {
    return (
      <span>
        <span>WELCOME TO THE</span> ROGER DUBUIS WATCHES AND WONDERS 2021
      </span>
    );
  }
  if (storeId === rogerDubuisWholeSaleId) {
    return (
      <span>
        <span>WELCOME TO THE</span> ROGER DUBUIS WHOLESALE
      </span>
    );
  }
  if (storeId === rogerDubuisRetailId) {
    return (
      <span>
        <span>WELCOME TO THE</span> ROGER DUBUIS RETAIL
      </span>
    );
  }
  if (storeId === chopardHighJewelleryId) {
    return (
      <span>
        <span>Welcome to</span> Chopard Haute Joaillerie.
      </span>
    );
  }
  if (storeId === chopardHappySportVirtualExpId) {
    return (
      <span>
        <span>WELCOME TO THE</span> CHOPARD HAPPY SPORT EXPERIENCE
      </span>
    );
  }
  if (
    storeId === inspifySiteId ||
    storeId === inspifyTestingSiteId ||
    storeId === inspifyGCCSiteId ||
    storeId === inspifyJPSilkRoadSiteId
  ) {
    return (
      <span>
        Immersive Storytelling
        <br />
        Meets Client Engagement
      </span>
    );
  }
  if (loungeTagLine) {
    return <span>{loungeTagLine}</span>;
  }
  if (isOnboardingBrand()) {
    return `${t('host_Onboard_lounge_welcome_message', {
      brand: t(`${brandName}`)
    })}.`;
  }
  return `${t('host_lounge_welcome_message', {
    brand: t(`${brandName}`)
  })}.`;
};

export const getLandingContactButton = (storeId: string, t: any) =>
  t(`vb:option_virtual_walkthrough`);

export const getPiagetBoutiqueAvailabilityText = (storeId: string, t: any) => {
  switch (storeId) {
    case piagetSgBoutiqueId:
      return t('vb:piaget_boutique_opening_hours_disclaimer');
    case piagetMyBoutiqueId:
      return t('vb:piaget_my_boutique_opening_hours_disclaimer');
    case piagetAuBoutiqueId:
      return t('vb:piaget_au_boutique_opening_hours_disclaimer');
    case piagetVnBoutiqueId:
      return t('vb:piaget_vn_boutique_opening_hours_disclaimer');
    default:
      return '';
  }
};

export const getPostEndingVideoElement = (
  storeId: string,
  feedbackType?: FeedbackType,
  feedbackTypeId?: string
) => {
  const newLocal = [
    inspifySiteId,
    inspifyTestingSiteId,
    inspifyTestBrandSiteId
  ];
  if (newLocal.includes(storeId) || isOnboardingBrand()) {
    return <InspifyFeedback type={feedbackType} id={feedbackTypeId} />;
  }
  return null;
};

export const getOutroComponent = (
  storeId: string,
  closingVideoUrl: string,
  showOutro: boolean,
  feedbackType?: FeedbackType,
  feedbackTypeId?: string,
  fullSize?: boolean,
  advisorEndsMeeting?: boolean
) => {
  if (storeId === loubiFutureSiteId) {
    return <LFExitVideo />;
  }
  if (closingVideoUrl) {
    return (
      <div className="outro-full-screen-video">
        <GenericClosingVideo
          closingVideoUrl={closingVideoUrl}
          showOutro={showOutro}
        >
          {getPostEndingVideoElement(storeId, feedbackType, feedbackTypeId)}
        </GenericClosingVideo>
        {showOutro && getOutroVideoLogo(storeId)}
        <style jsx>
          {`
            :global(.outro-full-screen-video video) {
              object-fit: ${fullSize ? 'cover' : 'contain'};
            }
          `}
        </style>
      </div>
    );
  }
  if (isOnboardingBrand() && !closingVideoUrl) {
    return (
      <div>
        {(showOutro || advisorEndsMeeting) && (
          <div className="show-feedback">
            <InspifyFeedback type={feedbackType} id={feedbackTypeId} />
            <style jsx>
              {`
                :global(.show-feedback .feedback) {
                  z-index: 2002;
                }
              `}
            </style>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export const getOutroVideoLogo = (storeId: string) => {
  const storeIDsForPoweredByInspify = [
    iwcWW2021Id,
    inspifySiteId,
    inspifyTestingSiteId,
    inspifyGCCSiteId,
    inspifyJPSilkRoadSiteId
  ];
  return storeIDsForPoweredByInspify.includes(storeId) ? (
    <>
      <div className="powered-container">
        <PoweredByInspify position="right bottom" color="light" />
      </div>
      <style jsx>
        {`
          .powered-container {
            position: fixed;
            bottom: 10px;
            right: 0;
            width: 150px;
            z-index: 2003;
          }
        `}
      </style>
    </>
  ) : null;
};

export const getWelcomeVideo = (
  welcomeVideoUrl: string,
  allowSkip?: boolean,
  fullSize?: boolean
) => {
  if (!welcomeVideoUrl) return null;
  return (
    <div className="welcome-full-screen-video">
      <GenericWelcomeVideo
        welcomeVideoUrl={welcomeVideoUrl}
        allowSkip={allowSkip}
      />
      <style jsx>
        {`
          :global(.welcome-full-screen-video video) {
            object-fit: ${fullSize ? 'cover' : 'contain'};
          }
        `}
      </style>
    </div>
  );
};

export const getPiagetBoutiqueChatButton = (
  storeId: string,
  contacts: IVirtualBoutiqueContact[]
) => {
  switch (storeId) {
    case piagetVnBoutiqueId:
      return <ZaloChatButton contacts={contacts} />;
    default:
      return <ChatButton contacts={contacts} />;
  }
};

export const getPiagetBoutiqueEnquireButton = (
  storeId: string,
  contacts: EnquireNowContact[]
) => {
  switch (storeId) {
    case piagetVnBoutiqueId:
      return <EnquireButton contacts={[contacts[0]]} />;
    default:
      return <EnquireButton contacts={contacts} />;
  }
};

export const getChatPanelExtraLogo = (storeId: string) => {
  switch (storeId) {
    case piagetVnBoutiqueId:
      return (
        <div className="extra-logo">
          <img
            src="https://panos2.inspify.io/virtual_boutique/bc57587b-9ce9-43de-90c3-e035192d7739/tamson_logo_dark.png"
            alt="tamson"
          />
        </div>
      );
    default:
      return null;
  }
};

export const getStoreLocationButton = (storeId: string, openLocation: any) => {
  switch (storeId) {
    case piagetVnBoutiqueId:
      return <StoreLocationButton openLocation={openLocation} />;
    default:
      return null;
  }
};

export const getStoreLocationComponent = (storeId: string, onClose: any) => {
  switch (storeId) {
    case piagetVnBoutiqueId:
      return <StoreLocation onClose={onClose} />;
    default:
      return null;
  }
};

export const getStoreFreeDeliveryText = (storeId: string, t: any) => {
  if (storeId === iwcNlAmsterdamSiteId) {
    return t('vb:complimentary');
  }

  return t('vb:free');
};

export const getAdvisorCustomControls = (params: {
  storeId?: string;
  brandId?: string;
  onCloseMenu?: () => void;
  meetingState?: IMeeting;
}) => {
  const { storeId, brandId, onCloseMenu, meetingState } = params;
  if (
    [
      loubiFutureSiteId,
      loubiAirwaysBoutiqueId,
      loubiAirwaysCnBoutiqueId,
      loubiAirwaysJpBoutiqueId
    ].includes(storeId)
  ) {
    return null;
  }
  if (
    storeId === inspifyTestingSiteId ||
    storeId === inspifySiteId ||
    storeId === inspifyGCCSiteId ||
    storeId === inspifyJPSilkRoadSiteId
  ) {
    return (
      <InspifyAdvisorMeetingControls
        meeting={meetingState}
        onCloseMenu={onCloseMenu}
      />
    );
  }
  if (storeId === chopardHappySportGenevaHQTraining) {
    return (
      <ChopardAdvisorMeetingControls
        meeting={meetingState}
        onCloseMenu={onCloseMenu}
      />
    );
  }
  if (storeId === mercedesBenzStoreId) {
    return (
      <MercedesAdvisorMeetingControl
        meeting={meetingState}
        onCloseMenu={onCloseMenu}
      />
    );
  }
  return (
    <AdvisorAccessControls
      storeId={storeId}
      brandId={brandId}
      meeting={meetingState}
      onCloseMenu={onCloseMenu}
    />
  );
};

export const getAdditionalStorybookComponent = (
  storybookId: string,
  storybookBrand: string
): SBMicrositeAdditionalComponent => {
  if ([inspifyBrandId, inspifyTestBrandId].includes(storybookBrand)) {
    return {
      component: (
        <>
          <InspifyFeedback type="STORYBOOK" id={storybookId} />
          <style jsx>
            {`
              @media (max-height: 500px) {
                :global(.feedback .veil) {
                  top: -30px;
                }
              }
            `}
          </style>
        </>
      ),
      thumbnail: `${assetBaseUrl}/feedback_thumbnail.jpg`
    };
  }
  return null;
};

export const getPSLandingComponent = (
  storeId: string,
  isLoading: boolean,
  isConnecting: boolean,
  isPlaying: boolean
) => {
  const props = {
    isLoadingPS: isLoading,
    isConnecting,
    isStreamPlaying: isPlaying
  };
  if (storeId === inspifyInverseSiteId) {
    return <InverseLanding {...props} />;
  }
  if (storeId === mercedesBenzSiteId) return <MercedesLanding {...props} />;

  return <GenericLanding {...props} />;
};

export const getPSDisconnectedComponent = (
  storeId: string,
  onReconnect: () => void
) => {
  if (storeId === inspifyInverseSiteId) {
    return <InverseDisconnect onReconnect={onReconnect} />;
  }
  return <GenericDisconnected onReconnect={onReconnect} />;
};

export const getPSCustomComponent = (
  storeId: string,
  sendCommand: (descriptor: any) => void
) => {
  if (storeId === mercedesBenzSiteId) {
    return <MercedesCustomComponent onSendCommand={sendCommand} />;
  }
};
